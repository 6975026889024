<template>
  <div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="资金相关" name="first">
        <div style="margin: 50px 0px 0px 50px">
          <span class="label-text">解冻流水：</span>
          <span>用户ID：</span>
          <el-input v-model="unFrozeUserid" placeholder="请输入需要解冻的用户ID" style="width: 200px" />
          <span style="margin-left: 20px">流水号：</span>
          <el-input v-model="serialNo" placeholder="请输入需要流水号" style="width: 200px" />
          <el-button type="primary" @click="toUnFroze" style="margin-left: 20px">解冻</el-button>
        </div>
        <div style="margin: 50px 0px 0px 15px; display: flex; align-items: center">
          <span class="label-text" style="width: 150px; margin-left: 90px">司机提现合同签署控制：</span>
          <el-switch v-model="driverTaxSwitchInfo.KeyValue" active-color="#13ce66" inactive-color="#ccc" active-text="开"
            inactive-text="关" :active-value="1" :inactive-value="0">
          </el-switch>
          <el-button type="primary" @click="toUpgrade(driverTaxSwitchInfo)" style="margin-left: 20px">更新配置</el-button>
        </div>
        <div style="margin: 50px 0px 0px 50px; display: flex; align-items: center">
          <span class="label-text">提现控制：</span>
          <el-switch v-model="withdrawInfo.KeyValue" active-color="#13ce66" inactive-color="#ccc" active-text="开"
            inactive-text="关" :active-value="1" :inactive-value="0">
          </el-switch>
          <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" placeholder="请输入备注"
            v-model="withdrawInfo.KeyText" clearable style="width: 350px; margin-left: 20px">
          </el-input>
          <el-button type="primary" @click="toUpgrade(withdrawInfo)" style="margin-left: 20px">更新配置</el-button>
        </div>
        <div style="margin: 50px 0px 0px 100px; display: flex; align-items: center">
          <span class="label-text">线上业务流程控制：</span>
          <el-switch v-model="bankTransInfo.KeyValue" active-color="#13ce66" inactive-color="#ccc" active-text="开"
            inactive-text="关" :active-value="1" :inactive-value="0">
          </el-switch>
          <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" placeholder="请输入备注"
            v-model="bankTransInfo.KeyText" clearable style="width: 350px; margin-left: 20px">
          </el-input>
          <el-button type="primary" @click="toUpgrade(bankTransInfo)" style="margin-left: 20px">更新配置</el-button>
        </div>
        <div style="margin: 50px 0px 0px 100px">
          <span class="label-text">冻结货主提现权限：</span>
          <span>货主ID：</span>
          <el-input v-model="withdrawalControlInfo.ownerUserId" placeholder="请输入货主ID" style="width: 200px" />
          <span style="margin-left: 20px">结算单号：</span>
          <el-input v-model="withdrawalControlInfo.invoiceTaskId" placeholder="请输入结算单号" style="width: 200px" />
          <el-button type="primary" @click="toWithdrawalControl" style="margin-left: 20px">设置</el-button>
          <div style="
              margin: 15px 0px 15px 100px;
              display: flex;
              align-items: center;
            ">
            <div style="width: 400px">
              <span style="margin-left: 20px" class="label-text">控制类型：</span>
              <el-radio v-model="withdrawalControlInfo.controlType" :label="0">按货主控制</el-radio>
              <el-radio v-model="withdrawalControlInfo.controlType" :label="1">按结算单控制</el-radio>
            </div>
            <div style="width: 350px">
              <span style="margin-left: 20px" class="label-text">是否可提现：</span>
              <el-radio v-model="withdrawalControlInfo.isWithdrawal" :label="true">是</el-radio>
              <el-radio v-model="withdrawalControlInfo.isWithdrawal" :label="false">否</el-radio>
            </div>
            <div style="width: 350px">
              <span style="margin-left: 20px" class="label-text">是否冻结货主：</span>
              <el-radio v-model="withdrawalControlInfo.isControlOwner" :label="true">是</el-radio>
              <el-radio v-model="withdrawalControlInfo.isControlOwner" :label="false">否</el-radio>
            </div>
          </div>
          <div style="
              margin: 15px 0px 15px 100px;
              display: flex;
              align-items: center;
            ">
            <div style="width: 400px">
              <span style="margin-left: 20px" class="label-text">是否冻结服务商：</span>
              <el-radio v-model="withdrawalControlInfo.isControlSupplier" :label="true">是</el-radio>
              <el-radio v-model="withdrawalControlInfo.isControlSupplier" :label="false">否</el-radio>
            </div>
            <div style="width: 350px">
              <span style="margin-left: 20px" class="label-text">是否冻结司机：</span>
              <el-radio v-model="withdrawalControlInfo.isControlDriver" :label="true">是</el-radio>
              <el-radio v-model="withdrawalControlInfo.isControlDriver" :label="false">否</el-radio>
            </div>
            <div style="width: 350px">
              <span style="margin-left: 20px" class="label-text">是否冻结平台：</span>
              <el-radio v-model="withdrawalControlInfo.isControlPlatform" :label="true">是</el-radio>
              <el-radio v-model="withdrawalControlInfo.isControlPlatform" :label="false">否</el-radio>
            </div>
          </div>
        </div>
      </el-tab-pane>

      <el-tab-pane label="运单相关" name="second">
        <div style="margin: 50px 0px 0px 100px">
          <span class="label-text">结算单发票状态查询：</span>
          <el-input v-model="invoiceTaskId" placeholder="请输入结算单号" style="width: 300px" />
          <el-button type="primary" @click="toQueryStatus" style="margin-left: 20px">查询</el-button>
          <el-button @click="toClear" style="margin-left: 5px">清空</el-button>
        </div>
        <div v-if="invoiceTaskInfo" style="margin: 20px 0px 0px 80px">
          <el-descriptions class="margin-top" title="" :column="4" border style="width: 65%">
            <el-descriptions-item label="场景类型" content-class-name="my-content">{{ invoiceTaskInfo.TaskScenceType
            }}</el-descriptions-item>
            <el-descriptions-item label="结算单票种" content-class-name="my-content">{{ invoiceTaskInfo.InvoiceKind }}-{{
              invoiceTaskInfo.InvoiceKindTec
            }}</el-descriptions-item>
            <el-descriptions-item label="支付方式" content-class-name="my-content">{{ invoiceTaskInfo.PayMode
            }}</el-descriptions-item>
            <el-descriptions-item label="结算单状态" content-class-name="my-content">{{ invoiceTaskInfo.Status
            }}</el-descriptions-item>
          </el-descriptions>
        </div>
        <div style="margin: 50px 0px 0px 75px">
          <span class="label-text">修改运单库场景：</span>
          <span>待结算单号：</span>
          <el-input v-model="UpdateWaybillTasksceneInfo.ImportBatchID" placeholder="请输入待结算单号：" style="width: 200px" />
          <span style="margin-left: 20px">场景类型编号：</span>
          <el-input v-model="UpdateWaybillTasksceneInfo.TaskSceneTypeID" placeholder="请输入场景类型编号：" style="width: 200px" />
          <el-button type="primary" @click="UpdateWaybillTaskscene" style="margin-left: 20px">修改</el-button>
        </div>
        <div style="margin: 50px 0px 0px 103px">
          <span class="label-text">修改人工审核服务商：</span>
          <span>待结算单号：</span>
          <el-input v-model="UpdateManualProviderInfo.ImportBatchID" placeholder="请输入待结算单号：" style="width: 200px" />
          <span style="margin-left: 20px">服务商Id：</span>
          <el-input v-model="UpdateManualProviderInfo.ProviderID" placeholder="请输入服务商Id：" style="width: 200px" />
          <el-button type="primary" @click="UpdateManualProvider" style="margin-left: 20px">修改</el-button>
        </div>
      </el-tab-pane>

      <el-tab-pane label="其他" name="third">
        <div style="margin: 50px 0px 0px 100px">
          <span class="label-text">解锁平台账号：</span>
          <el-input v-model="phone" placeholder="请输入需要解锁的平台账号" style="width: 300px" />
          <el-button type="primary" @click="toDeblocking" style="margin-left: 20px">解锁</el-button>

          <span class="label-text" style="margin-left: 180px">法大大授权协议：</span>
          <el-input v-model="userId" placeholder="请输入用户ID" style="width: 300px" />
          <el-button type="primary" @click="toDownLoad" style="margin-left: 20px">下载</el-button>
        </div>
        <div style="margin: 50px 0px 0px 100px">
          <span class="label-text">刷新OCR配置：</span>
          <el-button type="primary" @click="toRefreshOcrConfig">刷新</el-button>
        </div>
        <div style="margin: 50px 0px 0px 100px; display: flex; align-items: center">
          <span class="label-text">登录白名单配置：</span>
          <el-switch v-model="OnlineUsers.KeyValue" active-color="#13ce66" inactive-color="#ccc" active-text="开"
            inactive-text="关" :active-value="1" :inactive-value="0">
          </el-switch>
          <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" placeholder="请输入用户ID"
            v-model="OnlineUsers.KeyText" clearable style="width: 350px; margin-left: 20px">
          </el-input>
          <el-button type="primary" @click="toUpgrade(OnlineUsers)" style="margin-left: 20px">更新配置</el-button>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import {
  DeblockingLoginErrorPhone,
  GetUserAutoSignContract,
  GetInvoiceTypes,
  GetSysValueAsync,
  EditSysConfig,
  UnFroze,
  WithdrawalControl,
  UpdateWaybillTaskscene,
  UpdateManualProvider,
  RefreshOcr,
} from "@/api/configFlie/index";
export default {
  data() {
    return {
      activeName: "first",
      phone: "",
      userId: "",
      invoiceTaskId: "", // 结算单号
      invoiceTaskInfo: "", // 查询到的结算单信息
      withdrawInfo: {}, // 提现控制
      bankTransInfo: {}, // 线上业务流程控制
      serialNo: "", // 流水号
      unFrozeUserid: "", // 用户id
      withdrawalControlInfo: {
        ownerUserId: "",
        invoiceTaskId: "",
        controlType: 0,
        isWithdrawal: true,
        isControlOwner: true,
        isControlSupplier: true,
        isControlDriver: true,
        isControlPlatform: false,
      }, // 冻结货主提现权限
      driverTaxSwitchInfo: {}, // 司机提现合同签署控制
      UpdateWaybillTasksceneInfo: {
        ImportBatchID: "",
        TaskSceneTypeID: 0,
      }, //修改运单库场景
      UpdateManualProviderInfo: {
        ImportBatchID: "",
        ProviderID: 0,
      },
      OnlineUsers: {}, // 白名单
    };
  },
  created() {
    // 获取配置项目
    this.getSysValueAsync();
    // 获取司机提现合同签署控制
    this.getDriverTaxSwitch();
  },
  methods: {
    // 解锁账号
    toDeblocking() {
      if (!this.phone) {
        this.$message.error("请输入账号");
        return;
      }
      this.$confirm("确定解锁此账号?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        DeblockingLoginErrorPhone({ phone: this.phone }).then((res) => {
          this.$message.success("解锁成功");
          this.phone = "";
        });
      });
    },
    // 获取法大大授权协议
    toDownLoad() {
      if (!this.userId) {
        this.$message.error("请输入用户ID");
        return;
      }
      GetUserAutoSignContract({ userid: this.userId }).then((res) => {
        window.location.href = res.data;
        this.$message.success("操作成功");
        this.userId = "";
      });
    },
    // 刷新ocr配置
    toRefreshOcrConfig() {
      RefreshOcr().then(() => {
        this.$message.success("刷新成功");
      });
    },

    // 查询结算单发票状态
    toQueryStatus() {
      if (!this.invoiceTaskId) {
        this.$message.error("请输入结算单号");
        return;
      }
      GetInvoiceTypes({ invoiceTaskId: this.invoiceTaskId }).then((res) => {
        this.invoiceTaskInfo = res.data || {};
        this.$message.success("查询成功");
      });
    },
    // 清空结算单号
    toClear() {
      this.invoiceTaskId = "";
      this.invoiceTaskInfo = "";
    },
    // 查询配置
    getSysValueAsync() {
      // 转账
      GetSysValueAsync({
        keyType: "P00001",
        keyName: "BankTransSwitch",
      }).then((res) => {
        this.bankTransInfo = res.data || {};
      });
      // 提现
      GetSysValueAsync({
        keyType: "P00001",
        keyName: "BankWithdSwitch",
      }).then((res) => {
        this.withdrawInfo = res.data || {};
      });
      // 白名单
      GetSysValueAsync({
        keyType: "P00001",
        keyName: "OnlineUsers",
      }).then((res) => {
        this.OnlineUsers = res.data || {};
      });
    },
    // 司机提现合同签署控制
    getDriverTaxSwitch() {
      GetSysValueAsync({
        keyType: "P00001",
        keyName: "DriverTaxSwitch",
      }).then((res) => {
        this.driverTaxSwitchInfo = res.data || {};
      });
    },
    // 更新配置
    toUpgrade(item) {
      this.$confirm("确定更新配置吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        EditSysConfig(item).then((res) => {
          this.$message.success("更新成功");
          if (item.KeyName == "DriverTaxSwitch") {
            this.getDriverTaxSwitch();
          } else {
            this.getSysValueAsync();
          }
        });
      });
    },
    // 解冻流水
    toUnFroze() {
      if (!this.unFrozeUserid) {
        this.$message.error("请输入用户ID");
        return;
      }
      if (!this.serialNo) {
        this.$message.error("请输入流水号");
        return;
      }
      this.$confirm("确定解冻吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        UnFroze({ userid: this.unFrozeUserid, serialNo: this.serialNo }).then(
          (res) => {
            this.$message.success("解冻成功");
            this.unFrozeUserid = "";
            this.serialNo = "";
          }
        );
      });
    },
    // 设置冻结货主提现权限
    toWithdrawalControl() {
      if (!this.withdrawalControlInfo.ownerUserId) {
        this.$message.error("请输入货主Id");
        return;
      }
      if (
        this.withdrawalControlInfo.controlType == 1 &&
        !this.withdrawalControlInfo.invoiceTaskId
      ) {
        this.$message.error("请输入结算单号");
        return;
      }
      this.$confirm("确定设置吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        WithdrawalControl(this.withdrawalControlInfo).then((res) => {
          this.$message.success("设置成功");
          this.withdrawalControlInfo = {
            ownerUserId: "",
            invoiceTaskId: "",
            controlType: 0,
            isWithdrawal: true,
            isControlOwner: true,
            isControlSupplier: true,
            isControlDriver: true,
            isControlPlatform: false,
          };
        });
      });
    },

    //修改运单库场景
    UpdateWaybillTaskscene() {
      if (!this.UpdateWaybillTasksceneInfo.ImportBatchID) {
        this.$message.error("请输入待结算单号");
        return;
      }
      if (this.UpdateWaybillTasksceneInfo.TaskSceneTypeID == 0) {
        this.$message.error("请输入场景类型编号");
        return;
      }
      this.$confirm("确定修改吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        UpdateWaybillTaskscene(this.UpdateWaybillTasksceneInfo).then((res) => {
          this.$message.success("修改成功");
          this.UpdateWaybillTasksceneInfo.ImportBatchID = "";
          this.UpdateWaybillTasksceneInfo.TaskSceneTypeID = 0;
        });
      });
    },

    // 修改人工审核服务商
    UpdateManualProvider() {
      if (!this.UpdateManualProviderInfo.ImportBatchID) {
        this.$message.error("请输入待结算单号");
        return;
      }
      if (this.UpdateManualProviderInfo.ProviderID == 0) {
        this.$message.error("请输入服务商Id");
        return;
      }
      this.$confirm("确定修改吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        UpdateManualProvider(this.UpdateManualProviderInfo).then((res) => {
          this.$message.success("修改成功");
          this.UpdateManualProviderInfo.ImportBatchID = "";
          this.UpdateManualProviderInfo.ProviderID = 0;
        });
      });
    },
  },
};
</script>
<style lang="scss">
.my-content {
  font-weight: bold !important;
}

.label-text {
  font-weight: bold;
  text-align: right;
  width: 130px;
  display: inline-block;
}
</style>
